.constainer-box-actions {
    position: absolute;
    top: 7px;
    right: 20px;
    display: flex;
    justify-content: center;
    height: 40px;

    .child-box {
        display: flex;
        justify-content: center;
        margin-left: 4px;
    }
}

.row-fh {
    height: calc(100vh - 124px);

    .centered {
        // position: absolute;
        text-align: center;
        // top: 40%;
        // left: 48%;
        width: 240px;
        margin: auto;
    }
}

.product-modal {
    min-width: 800px !important;
    max-width: 1000px !important;

    .filter-list {
        margin-bottom: 16px;

        p {
            margin-bottom: 4px;
            letter-spacing: 1.2px;
            font-size: 12px;
        }

        span {
            margin-left: 8px;
            font-size: 9px;
            color: #a7a9b4 !important;
        }

        .list-item {
            margin-left: 12px;
        }
    }

    .product-btn {
        border: 0;
        padding: 14px 18px;
        color: #ffffff !important;
        font-size: 18px;

        &.cancel {
            background-color: #dadee3;
        }

        &.done {
            background-color: #7CC6D6;
        }
    }
}

.submit-modal {
    .warning-icon {
        font-size: 18px;
        position: absolute;
    }

    .submit-header {
        margin-left: 24px;
    }

    .body-bordered {
        border-top: 1px solid rgba(40, 40, 40, 0.2);
        border-bottom: 1px solid rgba(40, 40, 40, 0.2);
    }

    .okay-btn {
        border: 0;
        background-color: #7CC6D6;
    }
}

.input-disabled {
    background-color: #f5f6f8 !important;
    color: #c3c3c3 !important;
    border: 0 !important;
}

@media (max-width: 700px) {
    .constainer-box-actions {
        position: relative;
        margin: 10px 0px;
        display: flex;
        justify-content: center;
        top: unset;
        right: unset;

        .child-box {
            display: flex;
            justify-content: center;
            margin-left: 4px;
        }
    }
}

.wrapper-text {
    position: relative;
    display: flex;
    align-items: center;

    .title {
        justify-content: flex-start;
        align-items: center;
    }

    .box-1 {
        display: flex;
        align-items: center;
        margin-left: 6px;
    }

    .secondary {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0px;
        max-width: 300px;
    }

    .none {
        width: auto;
    }
}

.container-box.split-2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .left-content {
        width: 80%;
        justify-content: flex-start;
    }

    .right-content {
        // width: 30%;
        justify-content: flex-end;
        // text-align: right;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 700px) {
    .container-box.split-2 {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        .left-content {
            width: 50%;
            justify-content: flex-start;
        }

        .right-content {
            width: 50%;
            justify-content: flex-end;
            text-align: right;
            display: flex;
            align-items: center;
        }
    }
}

.text-light-muted {
    color: #ffffffa7;
}

.img-bg-card {
    position: relative;
    display: block;
    height: 200px;
    width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // box-shadow: 0px 1px 2px rgb(190 191 204);
    border-radius: 8px;
    overflow: hidden;
}

.custom-card {
    // display: 'flex', justifyContent: 'space-between ', width: '100%', height: 70, overflow: 'hidden'
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
}

.custom-card-body {
    // borderRadius: 4, display: 'flex', backgroundColor: '#f1f5f7', height: '100%', width: 70, textAlign: 'center', justifyContent: 'center', alignItems: 'center' 
    border-radius: 4px;
    display: flex;
    background-color: #f1f5f7;
    height: 74px;
    width: 74px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    .size-img-sm {
        height: 40px !important;
        width: 40px !important;
    }

    .rounded-circle {
        border-radius: 50%;
    }
}

.size-content {
    padding-left: 10px;
    width: calc(100% - 96px);
}

.size-action {
    width: 100px;
    // padding: 14px;
}

.rs-float-right {
    float: right;
}

@media (max-width: 700px) {
    .rs-float-right {
        float: none;
    }
}

.custom-header-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: left;
    width: 40%;
    min-width: 40%;
    max-width: 60%;
}

.custom-header-sub-title {
    display: flex;
    width: 100%;
}

.empty-note {
    position: relative;
    display: flex;
    height: 200px;
    width: 100%;
    align-items: center;
    justify-content: center;
    // font-size: 3rem;
    opacity: 0.2;
}

@keyframes loader-animation {
    0% {
        left: -100%;
    }

    49% {
        left: 100%;
    }

    50% {
        left: 100%;
    }

    100% {
        left: -100%;
    }
}

.loader-container {
    position: absolute;
    overflow: hidden;
    display: block;
    top: 45px;
    left: 12.2px;
    width: 97.8%;
}

.loader {
    height: 2px;
    width: 100%;
    background-color: #5664d220;
}

.loader .bar {
    width: 100%;
    position: absolute;
    height: 2px;
    background-color: #5664d250;
    animation-name: loader-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}