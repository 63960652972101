
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

.single-drop {
  min-height: 100%;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

.is-valid {
  border: 2px dashed #ff3d60;
}