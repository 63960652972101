//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";


.accordian-bg-products {
    background-color: $gray-300;
}

.auth-form-group-custom .form-control {
    padding-right: 36px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.visible-icon-password {
    right: 15px;
    position: absolute;
    top: 66%;
    transform: translateY(-50%);
    font-size: 14px;
    z-index: 10;
    color: #5438DC;
    cursor: pointer;
}

.nav-link {
    cursor: pointer;
}


.is-cursor {
    cursor: pointer !important;

    &.cursor-img {
        &:hover {
            opacity: 0.75;

            .btn-view {
                display: block;
            }
        }

        .btn-view {
            display: none;
        }
    }


}

.dataTables_filter,
.dataTables_paginate {
    float: right;

    .pagination {
        .page-link {
            border-radius: 30px !important;
            margin: 0 3px;
            border: none;
            text-align: center;
            cursor: pointer;
        }
    }
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    padding: 100px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.react-bootstrap-table {
    padding-top: 0px !important;
}

.square-switch {
    .react-switch-bg {
        border-radius: 5px !important;
    }
}

input[type="range"] {
    -webkit-appearance: auto !important;
}

.chat-conversation-height {
    min-height: 500px;
}

.expand-cell {
    cursor: pointer !important;
}

::-webkit-input-placeholder {
    /* Edge */
    color: #ced4da
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ced4da
}

::placeholder {
    color: #ced4da
}

.form-control::placeholder,
.css-1wa3eu0-placeholder {
    color: #ced4da !important
}

.select-red-select input {
    border: 0px
}

.select-red input {
    border: 1px solid red !important;
}


.select-red-select .css-yk16xz-control,
.select-red-select .DayPickerInput input {
    border: 1px solid !important;
    border-color: red !important;
}

.viewContainer {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.viewBtn {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: 100;
    color: #ffffff00;
    transition: ease-in 200ms;
    opacity: 0;
}

.viewBtn:hover {
    width: 100%;
    background: #ffffff40;
    opacity: 1;
    transition: ease-in 200ms;
}

.hover-custom-table {
    cursor: pointer;
}

.hover-custom-table:hover {
    background-color: #f8f9fa;
}

.custom-searching-box {
    position: relative;
    display: block;
    background-color: #f1f5f7;
    border-radius: 0.25rem;

    input,
    input:focus {
        background: none;
        border: none;
        padding-left: 2.2rem;
    }

    i {
        color: #ced4da;
        position: absolute;
        top: .6rem;
        left: .8rem;
    }
}

.customized-checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #5438DC
}


.custom-validate .css-yk16xz-control {
    border: 1px solid #ff3d60
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-bootstrap {
    width: 100% !important;
}

.ql-toolbar.ql-snow {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #ced4da;
}

.ql-container.ql-snow {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #ced4da;
}

.ql-snow .ql-editor img {
    width: 150px !important;
}

.custom-dropdown-menu-right.dropdown-menu.show {
    top: 45px !important;
    right: -8px !important;
    left: unset !important;
    transform: unset !important;
}

.meter {
    overflow: hidden;
    border: 0px;
    position: relative;
}

.meter .animate-bg-color {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    height: 100%;
    background-color: #1cbb8c;
    background-image: linear-gradient(center bottom,
            rgb(43, 194, 83) 37%,
            rgb(84, 240, 84) 69%);
    overflow: hidden;
    z-index: 1;
}

.animate-label {
    position: relative;
    z-index: 10;
}

.custom-number-input {
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
        /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type=number] {
        -moz-appearance: textfield;
        /* Firefox */
    }
}

table td {
    vertical-align: middle !important;
    cursor: pointer;
}

.custom-dropItem {
    max-height: 200px;
    overflow: auto;
    border-radius: 4px;
}


.list-group-item {
    &.cursor-p {
        cursor: pointer !important;

        &:hover {
            background-color: #f3f3f7 !important;
        }
    }

    &.active {
        background-color: #5438DC !important;

        &:hover {
            background-color: #5438DC !important;
        }
    }
}

.show-only-print {
    display: none !important;
}

@media print {
    .hide-from-print {
        display: none !important;
    }

    .show-only-print {
        display: block !important;
    }
}

.border-dashed {
    border: 1px dashed rgb(206, 210, 219);
    border-radius: 4px;
    margin-bottom: 0.8rem;
    min-width: 300px;
    text-align: center;
}

.parent-water-mark {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;

    .water-mark {
        p {
            font-size: 20em;
            transform: rotate(310deg);
            opacity: 0.1;
            border-top: 15px solid;
            border-bottom: 15px solid;
            font-family: 'Roboto', sans-serif;
            font-style: italic;
            color: $danger;
            text-align: center;
        }
    }
}

.footer-container {
    z-index: 100;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 20px;
}

.box-container {
    max-width: 400px !important;
    margin: auto !important;
}

.card-hover {
    border: 1px solid $white !important;

    .btn-remove-cookie {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 50% !important;
        color: $danger !important;
        background-color: $white !important;


        &:hover {
            border: 1px solid $light !important;
            background-color: $danger !important;
            color: $white !important;
        }
    }

    &:hover {
        background-color: #fafafa !important;
        border: 1px solid $light !important;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);

        .btn-remove-cookie {
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05) !important;
        }
    }
}

.avatar-user {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fb;
    border-radius: 50%;
    border: 4px solid #f0f5f7;
    padding: 0.25rem;
    color: #5660d9;

    &.xs {
        font-size: 1.8rem;
        min-height: 36px;
        min-width: 36px;
    }

    &.lg {
        font-size: 2rem;
        min-height: 4rem;
        min-width: 4rem;
    }
}

.input-signature {
    position: relative;
    display: flex;
    min-width: 200px;
    width: 100%;
    height: 120px;
    border: 1px dashed #ced4da;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 10px;

    .dropzone {
        border: none !important
    }

    .line-signature {
        position: absolute;
        bottom: 40px;
        left: calc(20% / 2);
        border: .2px solid #31373d;
        margin: auto;
        width: 80%;
    }
}

.input-image {
    position: relative;
    display: flex;
    min-width: 125px;
    min-height: 125px;
    width: 100%;
    height: 100%;
    border: 1px dashed #ced4da;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    .dropzone {
        border: none !important;
        margin: none !important;
        padding: none !important;
    }
}

.list-li {
    div {
        div {
            ul {
                margin: 0px !important;
                padding-left: 15px !important;

                li {
                    font-size: 80%;
                    color: #74788d !important;
                }
            }
        }

        ul {
            margin: 0px !important;
            padding-left: 15px !important;

            li {
                font-size: 80%;
                color: #74788d !important;
            }
        }
    }
}

.form-control:disabled {
    color: #ced4da !important;
}

.popup-chat {
    z-index: 1000;
    bottom: 5px;
    right: 20px;
    border-radius: .3rem;
    overflow: hidden;
    width: 380px;
    background-color: white;

    &.show {
        transition: ease 500ms;
        box-shadow: -3px 3px 10px 4px rgba(0, 0, 0, 0.209) !important;

        .body-chat {
            transition: ease 500ms;
            position: relative;
            max-height: calc(500px - 120px);
            display: block;
            overflow: auto;
        }

        .footer-chat {
            height: 80px;
            bottom: 0;
            position: relative;
            display: flex;
            right: 0;
            width: 100%;

            .button-send {
                bottom: 23px;
                right: 28px;
            }
        }
    }

    &.hide {
        transition: ease 100ms;
        border: 1px solid #452bc9;

        .body-chat {
            transition: ease 500ms;
            max-height: 0px !important;
            padding: 0px !important;
            border: none !important;
        }

        .footer-chat {
            padding: 0px !important;
            transition: ease 500ms;
            height: 0px !important;
        }
    }

    .header-chat {
        height: 40px;
        bottom: 0;
        position: relative;
        display: flex;
        right: 0;
        width: 100%;
        align-items: center;
        cursor: pointer;
    }



    // .popup-body {
    //     position: relative;
    //     display: block;


    //     .popup-header {
    //         cursor: pointer;
    //         transition: ease 500ms;
    //         background-color: $primary;
    //         color: $light;
    //         font-size: 1rem;

    //         &:hover {
    //             transition: ease 500ms;
    //             background-color: #452bc9;
    //         }

    //         .button-arrow-up {
    //             color: $light;
    //             font-size: 1.2rem;
    //             display: block;
    //             padding: 0px;
    //         }
    //     }

    //     .popup-content {
    //         position: relative;
    //         background-color: $white;

    //         &.show {
    //             height: 100%;
    //             transition: ease 500ms;
    //             opacity: 1;

    //             .popup-content-body {
    //                 // height: calc(100% - 65.78px);
    //                 padding: 14px;

    //                 .bubble {
    //                     overflow-wrap:break-word
    //                 }
    //             }

    //             .popup-content-footer {
    //                 position: relative;
    //                 padding: 14px;
    //                 bottom: 10px;

    //                 .button-send {
    //                     bottom: 22px;
    //                     right: 22px;
    //                 }
    //             }
    //         }

    //         &.hide {
    //             opacity: 0;
    //             height: 0px;
    //             transition: ease 200ms;
    //         }
    //     }
    // }
}

.dropdown-table {
    z-index: 999999;
    position: absolute;
    left: 0;
    top: 0;
}

.bg-none {
    background-color: transparent !important;
    background: transparent !important;
}

.bg-info-coming-soon {
    background: linear-gradient(356deg, rgba(70, 174, 244, 0.9), rgba(70, 174, 244, 0.5)) !important;
    z-index: 10;
}

.dropzone {
    &.dropzone-custom {
        min-height: auto !important;
    }
}

.ef {
    // transition: 800ms ease-in-out !important;
}

.container-box-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    z-index: 100;
    background-color: white;
    overflow: hidden;
    padding: 1rem;
}

.footer-order {
    transition: 1000ms ease-in-out;
    user-select: none;
    height: 72px;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 4px 2px;

    &.expand {
        height: 400px;
    }
}

.mini-cam {
    opacity: 0;
    height: 150px;
    width: 300px;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 99999;
}

.circle {
    width: 10px;
    height: 10px;
    margin-right: 0.25rem;
    background-color: red;
    border-radius: 50%;
    position: relative;
  }
  
  .circle:before, .circle:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle:before {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    animation: pulse 2s ease-out infinite;
  }
  
  .circle:after {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    animation: blink 1s ease-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  @keyframes blink {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  .modal-alert {
    top: 0;
    left: 0;
    background-color: #171c219f;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .table-border-container {
    overflow: hidden;
    border: 1px solid #cccccc !important;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .badge-custom {
    background-color: #ECFDF3;
    color:#027A48;
    border: 1px solid #027A48;
  }