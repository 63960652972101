.overlay-button {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff80;
    
}

.hover-hide:hover {
    opacity: 0;
    transform: scale(0);
    transition: transform 2s;
    transition-delay: 1s;
}

.hover-show {
    opacity: 1;
    transform: scale(1);
    transition: transform 2s;
    transition-delay: 1s;
}

.close-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3em;
    color: red;
}

.link-hover:hover {
    color: #2e3dad !important;
    text-decoration: underline !important;
}
