.customize-select {
    z-index: 10;
}

.custom-timepicker {
    width: 100%;
    font-size: 0.9rem;

    .rc-time-picker-input {
        padding: 0.47rem 0.75rem; 
        font-size: 0.9rem;
        height: calc(1.5em + 0.94rem + 2px);
    }

    .rc-time-picker-clear {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 11px;
        right: 12px;
    }
}

.DayPickerInput {
    width: 100%;

   input {
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        color: #505d69;
        height: calc(1.5em + 0.94rem + 2px);
        padding: 0.47rem 0.75rem;
        font-size: 0.9rem;
        width: 100%;
   }
}